import { render, staticRenderFns } from "./Salvar.vue?vue&type=template&id=a9b0478e&lang=pug"
import script from "./Salvar.vue?vue&type=script&lang=js"
export * from "./Salvar.vue?vue&type=script&lang=js"
import style0 from "./Salvar.vue?vue&type=style&index=0&id=a9b0478e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports